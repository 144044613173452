@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

button a {
  font-size: 1.5rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  transform: translateY(-100%);
  transition: transform 0.5s ease;
  font-family: 'Roboto', sans-serif;
}

.show-header {
  transform: translateY(0);
}

.hide-header {
  transform: translateY(-100%);
}

.bold-skill {
  font-weight:500;
  color: #ff003a;
}

.bold-project {
  font-weight:500;
  color: #FC007B;
}

.bold-demo {
  font-weight:500;
  color: #D722B8;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}